<template>
  <div>
   <v-dialog v-model="dialog" persistent max-width="500px" transition="dialog-transition" >
      <card-loading :loadingText="loadingText"></card-loading>
    </v-dialog>
</div>
</template>

<script>
  export default {
    name: 'dialogLoading',
    props: {
      loading: {
        type: Boolean,
        default: () => false
      },
      loadingText: {
        type: String,
        default: () => 'Carregando'
      },
    },
    data() {
      return {
        dialog: true
      }
    },
  }
</script>

<style scoped>
>>> .v-dialog {
  border-radius: 18px !important;
}

</style>