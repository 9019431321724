<template>
  <div>
    <v-menu
      ref="date_menu"
      v-model="date_menu"
      :return-value.sync="date_pick"
      :close-on-content-click="false"
      min-width="320px"
      max-width="350px"
      nudge-bottom="10"
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          :label="label"
          prepend-icon="mdi-calendar" 
          readonly
          v-on="on"
          :value="show_formated_date" :rules="required"
        ></v-text-field>
      </template>
      <v-date-picker
        ref="picker"
        :active-picker.sync="activePicker"
        v-if="!read_only"
        :show-current="show_current"
        v-model="date_pick"
        no-title
        scrollable
        full-width
        :min="min"
        :max="max"
      >
        <v-spacer></v-spacer>
        <v-btn
          text
          color="primary"
          @click="date_menu = false"
        >
          Cancelar
        </v-btn>
        <v-btn
          text
          color="primary"
          @click="$refs.date_menu.save(date_pick)"
        >
          OK
        </v-btn>
      </v-date-picker>
    </v-menu>
  </div>
</template>

<script>
import validations from "@/mixins/validations";
export default {
  name: 'CalendarDatePicker',
  mixins: [ validations ],
  props: {
    item: {
      type: Object,
    },
    label: {
      type: String,
    },
    max: {
      type: String,
    },
    min: {
      type: String,
    },
    show_current: {
      type: String,
    },
    date: {
      type: String,
      default: null
    },
    read_only: {
      type: Boolean,
      default: false
    },
    active_picker: {
      type: String,
      default: undefined
    }
  },
  data() {
    return {
      activePicker: null,
      date_pick: '',
      date_menu: false,
      min_date: this.$dayjs().format('YYYY-MM-DD'),
    }
  },
  mounted() {
  },
  // methods: {
  //   setDate(){
  //     this.date_pick = this.date != null ? this.date : ''
  //   }
  // },
  computed: {
    show_formated_date(){
      if(this.date != null){
        return this.$dayjs(this.date).format('DD/MM/YYYY')
      }else{
        return this.date_pick != '' ? this.$dayjs(this.date_pick).format('DD/MM/YYYY') : ''
      }
    },
  },
  watch: {
    date_menu(val) {
      if(this.active_picker == 'YEAR'){
        val && setTimeout(() => (this.activePicker = 'YEAR'))
      }
    },
    date_pick(nV) {
      if(this.item){
        let item = {...this.item}
        item.date = nV
        this.$emit('datePicked', item )
      }else{
        this.$emit('datePicked', nV )
      }
    },
    max(){
      if(this.$dayjs(this.date_pick).isAfter(this.max)){
        this.date_pick = ''
      }
    },
    min(){
      if(this.$dayjs(this.date_pick).isBefore(this.min)){
        this.date_pick = ''
      }
    },
  },

}
</script>

<style lang="scss" scoped>

</style>