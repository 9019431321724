let Admin = ['super_admin', 'admin', 'director']
let Coordinator = 'coordinator'
let Student = 'student'

const Forms = [
  {
    path: '/forms',
    name: 'Forms',
    meta: { 
      auth: true, 
      tab_name: 'Formulários',
      roles: [Admin],
      permissions: { act: 'read', on: 'Form' }
    },
    component: () => import('@/pages/forms/Forms.vue'),
    children: [
    //   
      {
        //path: '/members/:id',
        path: '/forms/show/:id',
        name: 'FormShow',
        meta: { 
          auth: true, 
          tab_name: 'Exibir formulário',
          roles: [Admin],
          permissions: { act: 'read', on: 'Form' }
        },
        component: () => import('@/components/forms/show/ShowForm.vue'),
      },
    ]
  },
  {
    path: '/my_forms',
    name: 'MyForms',
    meta: { 
      auth: true, 
      tab_name: 'Meus Formulários',
      roles: [Student],
      permissions: { act: 'read', on: 'Form' }
    },
    component: () => import('@/pages/forms/Forms.vue'),
  },
  // NAO ESTA CRIANDO NOVOS FORMULARIOS COMENTADO PARA NAO ACESSAR A ROTA
  // {
  //   path: '/new_form',
  //   name: 'NewForm',
  //   meta: { 
  //     auth: true, 
  //     tab_name: 'Novo Formulário',
  //     roles: [Admin],
  //     permissions: { act: 'create', on: 'Form' }
  //   },
  //   component: () => import(`@/pages/forms/NewForm.vue`)
  // },
  {
    path: '/edit_form/:id',
    name: 'EditForm',
    meta: { 
      auth: true, 
      tab_name: 'Editar Formulário',
      roles: [Admin],
      permissions: { act: 'update', on: 'Form' }
    },
    component: () => import(`@/pages/forms/NewForm.vue`)
  },
]


export default Forms