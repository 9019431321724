let Admin = ['super_admin', 'admin', 'director']

const EnrollmentCounters = [
  {
    path: '/enrollment_counters',
    name: 'DoneFormsEnrollmentCounters',
    meta: { 
      auth: true, 
      tab_name: 'Inscrições',
      roles: [Admin],
      permissions: { act: 'read', on: 'DoneForm' }
    },
    component: () => import('@/pages/done_forms_enrollment_counters/DoneFormsEnrollmentCounters.vue'),
  },
]


export default EnrollmentCounters