let Admin = ['super_admin', 'admin', 'director']
let Coordinator = 'coordinator'
let Student = 'student'

const Members = [
  {
    path: '/members',
    name: 'Members',
    meta: { 
      auth: true, 
      tab_name: 'Membros',
      roles: [Admin],
      permissions: { act: 'read', on: 'User' }
    },
    component: () => import('@/pages/member_list/Members.vue'),
    children: [
      {
        //path: '/members/:id',
        path: '/members/new',
        name: 'NewMember',
        meta: { 
          auth: true, 
          tab_name: 'Adicionar Membro',
          roles: [Admin],
          permissions: { act: 'create', on: 'User' }
        },
        component: () => import('@/components/members/create/NewMember.vue'),
      },
      {
        //path: '/members/:id',
        path: '/members/edit/:id',
        name: 'EditMember',
        meta: { 
          auth: true, 
          tab_name: 'Editar Membro',
          roles: [Admin],
          permissions: { act: 'update', on: 'User' }
        },
        component: () => import('@/components/members/create/NewMember.vue'),
      },
    ]
  },
]


export default Members