import Http from '../http'

export default {

  index(filter) {
    return Http.get(`/roles`, {params: {...filter}})
  },


  create(role){
    return Http.post(`/roles`, {role: {
      ...role
    }})
  },

  update(role){
    return Http.put(`/roles/${role.id}`, {
      role: {
        ...role,
      },
    });
  },

  show(id) {
    return Http.get(`/roles/${id}`);
  },

  delete(id){
    return Http.delete(`/roles/${id}`)
  }

}
