import Http from '@/api/http.js'

export default {
  index(filter){
    return Http.get(`/data_exports`, {params: { ...filter }});
  },

  show(id){
    return Http.get(`/data_exports/${id}`);
  },
  create(data_export){
    return Http.post(`/data_exports`, { data_export: {...data_export} } )
  },
  download(id){
    return Http.get(`/data_exports/${id}/download`);
  },
}


