import Vue from "vue";
import VueRouter from "vue-router";
import authRoutes from "@/_auth/authRoutes";
import UserRoutes from './app_routes/UserRoutes'
import CourseRoutes from './app_routes/CourseRoutes'
import MembersRoutes from './app_routes/MembersRoutes'
import FormsRoutes from './app_routes/FormsRoutes'
import CertificateRoutes from './app_routes/CertificateRoutes'
import DoneFormsEnrollmentCountersRoutes from './app_routes/DoneFormsEnrollmentCountersRoutes'
import GhostRatingRoutes from './app_routes/GhostRatingRoutes'
import DataExportRoutes from './app_routes/DataExportRoutes'
import { store, The_Ability } from "@/store/index";

Vue.use(VueRouter);


const routes = [
  {
    path: "/",
    name: "Home",
    meta: {
      auth: true,
      roles: ['super_admin', 'admin', 'director', 'coordinator', 'student', 'tech_secretary'],
      permissions: { act: 'read', on: 'Course' }
    },
    component: () => import("../pages/Home.vue"),
  },
  ...authRoutes,
  ...UserRoutes,
  ...CourseRoutes,
  ...MembersRoutes,
  ...FormsRoutes,
  ...CertificateRoutes,
  ...DoneFormsEnrollmentCountersRoutes,
  ...GhostRatingRoutes,
  ...DataExportRoutes,
  // {
  //   path: "/account",
  //   name: "Account",
  //   component: () => import("../pages/Account.vue"),
  //   meta: {
  //     // auth: true
  //   }
  // },
  {
    path: '*',
    name: 'Err404',
    meta: {
      permissions: { act: 'read', on: 'User' }
    },
    component: () => import('@/pages/Err404.vue'),
  },
];

Vue.router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});


function hasRequireRole(to){
  let user_role = store.state.User.user.role.name
  let permited_roles = [].concat.apply([], to.meta.roles);

  return permited_roles.includes(user_role)
}

function hasRequirePermission(to) {
  return new Promise((resolve) => {
    if (The_Ability.can(to.meta.permissions.act, to.meta.permissions.on)) {
      resolve(true);
    }else {
      resolve(false);
    }
  });
}

function checkUserAccessRights({to, from, next}) {
  if(store.state.User.user != null && to.meta.permissions){
    if (hasRequireRole(to)){
      hasRequirePermission(to).then((has) => {
        if (has){
          next();
        }else {
          next({name: 'Home'});
        }
      });
    }else{
      next({ name: 'Home' });
    }
  }else{
    next()
  }
}

Vue.router.beforeEach(async(to, from, next) => {
  document.title = 'CABBIO'
  next()
})

Vue.router.beforeResolve((to, from, next) => {
  checkUserAccessRights({to, from, next})
})

export default Vue.router;
