
<template>
  <div>
    <v-menu
      ref="menu"
      v-model="menu2"
      :close-on-content-click="false"
      :nudge-right="40"
      :return-value.sync="time"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="290px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="time"
          :label="label"
          prepend-icon="mdi-clock-time-four-outline"
          readonly
          v-bind="attrs"
          v-on="on"
          :rules="required"
        ></v-text-field>
      </template>
      <v-time-picker
        v-if="menu2"
        v-model="time"
        full-width
        @click:minute="$refs.menu.save(time)"
      >
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="menu = false" >
          {{ $t('cancel') }}
        </v-btn>
        <v-btn text color="primary" @click="saveTime()" >
          {{ $t('ok') }}
        </v-btn>
      </v-time-picker>
    </v-menu>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import validations from '@/mixins/validations';

export default {
  props: {
    label: {
      type: String,
      default: 'Hora'
    },
  },
  mixins: [
    validations
  ],
  data() {
    return {
      time: null,
      menu2: false,
      modal2: false,
    }
  },
  methods: {
    saveTime() {
      this.$refs.menu.save(this.time);
      this.$emit('timePicked', this.time)
    },
    ...mapActions({
      
    })
  },
  computed: {
    ...mapState({
      
    })
  },
  watch: {
    
  },
}
</script>

<style scoped>

</style>