let Admin = ['super_admin', 'admin', 'director']
let Coordinator = 'coordinator'
let Student = 'student'

const Courses = [
  {
    path: '/courses',
    name: 'Courses',
    meta: { 
      auth: true, 
      tab_name: 'Courses',
      roles: [Admin, Coordinator, Student, 'tech_secretary'],
      permissions: { act: 'read', on: 'Course' }
    },
    component: () => import(`@/pages/courses/AgendaOrList.vue`),
    children: [
      {
        path: '/course_rating_dashboard/:id',
        name: 'CourseRatingsDashboard',
        meta: { 
          auth: true, 
          tab_name: 'Avaliações do curso',
          roles: [Admin, 'tech_secretary'],
        },
        component: () => import(`@/components/course_rating_dashboard/CourseRatingDashboard.vue`)
      },
      {
        path: '/course_short/:id',
        name: 'ShortCourse',
        meta: { 
          auth: true, 
          tab_name: 'Detalhes do Curso',
          // roles: [Director, Manager, Supervisor],
        },
        component: () => import(`@/components/courses/CourseDetails.vue`)
      },
      {
        path: '/courses_calendar',
        name: 'CourseCalendar',
        meta: { 
          auth: true, 
          tab_name: 'Calendário dos cursos',
          roles: [Admin, Coordinator],
          permissions: { act: 'read', on: 'Course' } // duvida ? opçao de suspender esta na pagina
        },
        component: () => import(`@/components/courses/CourseIndexAgenda.vue`)
      },
      {
        path: '/courses_list',
        name: 'CourseList',
        meta: { 
          auth: true, 
          tab_name: 'Listagem dos cursos',
          roles: [Admin, Coordinator, Student, 'tech_secretary'],
          permissions: { act: 'read', on: 'Course' }
        },
        component: () => import(`@/components/courses/CourseIndexList.vue`)
      },
      {
        path: '/my_courses_list',
        name: 'MyCourseList',
        meta: { 
          auth: true, 
          tab_name: 'Listagem dos cursos',
          roles: [Student],
          permissions: { act: 'read', on: 'Course' }
        },
        component: () => import(`@/components/courses/StudentCourseIndexList.vue`),
        children: [
          {
            path: 'my_course/:id',
            name: 'ShowStudentCourse',
            meta: { 
              auth: true, 
              tab_name: 'Meu curso',
              roles: [Student],
              permissions: { act: 'read', on: 'Course' }
            },
            component: () => import(`@/components/courses/shared/SelectedCourseCardActions.vue`)
          },
        ]
      },
      {
        path: '/course/:id/select_candidates',
        name: 'Select',
        meta: { 
          auth: true, 
          tab_name: 'Seleção de alunos',
          roles: [Admin, Coordinator],
          permissions: { act: 'update', on: 'DoneForm' }
        },
        component: () => import(`@/components/candidate_select_done_forms/CandidateSelectIndex.vue`)
      },
      {
        path: '/course/:id/student_rating',
        name: 'StudentRating',
        meta: { 
          auth: true, 
          tab_name: 'Avaliação dos alunos',
          roles: [Admin, Coordinator],
          permissions: { act: 'update', on: 'Enrolled' }
        },
        component: () => import(`@/components/student_rating/StudentRatingIndex.vue`)
      },
    ]
  },
  {
    path: '/new_course',
    name: 'NewCourse',
    meta: { 
      auth: true, 
      tab_name: 'Novo Curso',
      roles: [Admin],
      permissions: { act: 'create', on: 'Course' }
    },
    component: () => import(`@/pages/courses/NewCourse.vue`)
  },

  {
    path: '/course/:id',
    name: 'EditCourse',
    meta: { 
      auth: true, 
      tab_name: 'Editar Curso',
      roles: [Admin],
      permissions: { act: 'update', on: 'Course' }
    },
    component: () => import(`@/pages/courses/NewCourse.vue`)
  },

  {
    path: '/course/:course_id/enroll',
    name: 'CourseEnrollment',
    params: true,
    meta: { 
      auth: true, 
      tab_name: 'Inscrição',
      roles: [Admin, Coordinator, Student],
      permissions: { act: 'create', on: 'DoneForm' }
    },
    component: () => import(`@/components/enrollment_and_rating/CourseEnrollment.vue`),
  },
  {
    path: '/course/:course_id/rate',
    name: 'CourseRating',
    params: true,
    meta: { 
      auth: true, 
      tab_name: 'Avaliação',
      roles: [Student],
      permissions: { act: 'create', on: 'DoneForm' }
    },
    component: () => import(`@/components/enrollment_and_rating/CourseRating.vue`),
  },
  {
    path: '/course/:course_id/manual_enrollment',
    name: 'NewManualEnrollment',
    meta: { 
      auth: true, 
      tab_name: 'Novo Formulário',
      roles: [Admin, Coordinator],
      permissions: { act: 'create', on: 'DoneForm' }
    },
    component: () => import(`@/components/enrollment_and_rating/ManualEnrollment.vue`)
  },
  {
    path: '/course/:id/course_ratings',
    name: 'CourseRatings',
    meta: { 
      auth: true, 
      tab_name: 'Avaliações do curso',
      roles: [Admin, Coordinator],
      permissions: { act: 'update', on: 'DoneForm' }
    },
    component: () => import(`@/components/course_ratings_done_forms/CourseRatingsList.vue`)
  },
  
]
export default Courses
