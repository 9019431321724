import Vue from "vue";
import Vuex from "vuex";

import {User, ability} from "./modules/UserVuex";
// import User from "./modules/UserVuex";
import App from "./modules/AppVuex";
import Notification from "./modules/NotificationVuex";
import Course from "./modules/CourseVuex";
import DoneForm from "./modules/DoneFormVuex";

Vue.use(Vuex);

export const The_Ability = ability

export const store = new Vuex.Store({
  modules: {
    App,
    User,
    Notification,
    Course,
    DoneForm
  }
});

// export default store;
