import Vue from "vue";
import day from 'dayjs' // load on demand
import 'dayjs/locale/pt-br' // load on demand

var weekOfYear = require('dayjs/plugin/weekOfYear')
day.extend(weekOfYear)

var isBetween = require('dayjs/plugin/isBetween')
day.extend(isBetween)

var isSameOrBefore = require('dayjs/plugin/isSameOrBefore')
day.extend(isSameOrBefore)

var isSameOrAfter = require('dayjs/plugin/isSameOrAfter')
day.extend(isSameOrAfter)

var localizedFormat = require('dayjs/plugin/localizedFormat')
day.extend(localizedFormat)

day.locale('pt-br') // use PTBR locale globally

// Vue.use(day);

Vue.prototype.$dayjs = day;
