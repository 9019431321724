
<template>
  <div>
    <v-card outlined style='border-radius: 5px' max-width="800">
      <v-card-text class="px-0 py-0">
        <v-btn-toggle dense class="d-flex justify-space-between">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="blue" v-bind="attrs" v-on="on" @click="previousYear()" :disabled="disabledPrevious">
                <v-icon color="white" >mdi-arrow-left</v-icon>
              </v-btn>
            </template>
            <span>Ano anterior</span>
          </v-tooltip>
          <div class="ml-4 mr-5 d-flex align-center">
            <div style="white-space: nowrap;">
              <v-icon size="20" class="mb-1" color="primary" left>mdi-calendar</v-icon>
              <span class="text-center grey--text text--darken-1 text-h6 " style="height: 25px;">{{year}}</span>
            </div>
          </div>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="blue"  v-bind="attrs" v-on="on" @click="nextYear()" :disabled="disabledNext">
                <v-icon color="white">mdi-arrow-right</v-icon>
              </v-btn>
            </template>
            <span>Próximo ano</span>
          </v-tooltip>
        </v-btn-toggle> 
      </v-card-text>
    </v-card>
  </div>
</template>

<script>

export default {
  props: {
    year: {
      type: Number,
    },
    disabledNext: {
      type: Boolean,
      default: false
    },
    disabledNext: {
      type: Boolean,
      default: false
    },
    disabledPrevious: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    nextYear(){
      let date = parseInt(this.$dayjs().year(this.year + 1).format('YYYY'))
      this.$emit('selectedYear', date)
    },
    previousYear(){
      let date = parseInt(this.$dayjs().year(this.year - 1).format('YYYY'))
      this.$emit('selectedYear', date)
    },
  },
}
</script>

<style scoped>

</style>