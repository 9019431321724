

const GhostRating = [
  {
    path: '/rating/:enr_id',
    name: 'GhostRating', 
    meta: { 
      auth: false, 
      tab_name: 'Avaliação do curso',
    },
    component: () => import(`@/pages/ghost_rating/GhostRating.vue`)
  },
  {
    path: '/ghost_rating_confirmation/',
    name: 'GhostRatingConfirmation', 
    meta: { 
      auth: false, 
      tab_name: 'Confirmação de avaliação',
    },
    component: () => import(`@/pages/ghost_rating/GhostRatingConfirmation.vue`)
  },
]

export default GhostRating