// import { ability, defineRulesFor } from "@/plugins/ability";
import Api from '@/api';

import { Ability } from "@casl/ability";
export const ability = new Ability();
// import ability from '../../plugins/ability';


export const User = {
  namespaced: true,
  state: {
    oneUser: null,
    user: null
  },
  mutations: {
    setCurrentUser(state, data) {
      state.user = data;
    },
    login(state, user) {
      // automatizada na configuração de parse e fetch user - plugins/vue-auth
      state.user = user;
      //console.log({user})
      let can = user.role.permissions.can
      // let can2 = []
      // for (let idx = 0; idx < can.length; idx++) {
      //   const element = can[idx];
      //   console.log({c: element.can});
      //   can2 = [...can2, ...element.can]
      // }
      ability.update(can);
    },
    logout(state) {
      state.user = null;
      ability.update([]);
    }
  },

  actions: {
    updateUserWithFormData(context, formData){
      let notification = {};

      return Api.User.updateWithFormData(formData).then((r)=>{
        context.commit("setCurrentUser", r.data)
        notification.type = 'success';
        notification.msg = 'Atualizado com sucesso';
      }).catch(error => {
        notification.type = 'error';
        notification.msg = `Erro: ${error}`;
        throw error
      }).finally(() => {
        context.dispatch('Notification/add', notification, {root: true});
      });
    },
    update(context, user){
      let notification = {};

      return Api.User.update(user).then((r)=>{
        context.commit("setCurrentUser", r.data)
        notification.type = 'success';
        notification.msg = 'Atualizado com sucesso';
      }).catch(error => {
        notification.type = 'error';
        notification.msg = `Erro: ${error}`;
        throw error
      }).finally(() => {
        context.dispatch('Notification/add', notification, {root: true});
      });
    },
    getCurrentUser(context, data) {
      context.commit("setCurrentUser", data);
      // if (data) context.commit("setTokenData", localStorage.auth_token_default);
    }
  }
};

// export default Users;
