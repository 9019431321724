import Http from '../http';

export default {
  index(filter) {
    return Http.get(`/done_items`, {params: {...filter}})
  },
  create(done_items) {
    return Http.post('/done_items', {done_items: {
      ...done_items
    }})
  },
  indexByDoneForm(done_form_id, filter) {
    return Http.get(`/done_forms/${done_form_id}/done_items`, {params: {...filter}})
  },
}