let Admin = ['super_admin', 'admin', 'director']
let Coordinator = 'coordinator'
let Student = 'student'

const DataExport = [
  {
    path: '/data_exports',
    name: 'DataExport',
    meta: { 
      auth: true, 
      tab_name: 'Membros',
      roles: [Admin],
      permissions: { act: 'read', on: 'User' }
    },
    component: () => import('@/pages/data_export/DataExport.vue'),
  },
]


export default DataExport