import Api from '@/api/index';
import dayjs from 'dayjs'

const Course = {
  namespaced: true,

  state: {
    one_course: null,
    all_courses: null
  },

  getters: {
    getOneCourse: (state) => {
      return state.one_course;
    },
    getAllCourses: (state) => {
      return state.all_courses;
    },
    coursesWithDateMapped: (state) => {
      if(state.all_courses != null){
        return state.all_courses.map(item => {
          item.start_date = dayjs(item.start_date).format('YYYY-MM-DD hh:mm')
          item.end_date = dayjs(item.end_date).format('YYYY-MM-DD hh:mm')
          return item
        })
      }
    },
  },

  mutations: {
    setAllCourse(state, courses) {
      state.all_courses = [...courses];
    },
    setOneCourse(state, course) {
      state.one_course = {...course};
    },
    clearAllCourse(state) {
      state.all_course = null;
    },
    clearOneCourse(state) {
      state.one_course = null;
    }
  },

  actions: {
    setAllCourses({commit, dispatch}, courses){
      commit('setAllCourse', courses)
    },
    indexByUserId({commit, dispatch}, filter){
      Api.Course.index(filter).then(r => r.data).then(r => {
        commit('setAllCourse', r)
      }).catch(error => {
        console.log(error)
      })
    },
    index({commit, dispatch}, filter){
      let notification = {};
      let action = filter.is_component_use_in_student_access && !filter.is_student_list_waiting_selection ? 'enrolled' : 'waiting_selection'

      if(filter.is_component_use_in_student_access){

        return Api.MyCourse.index({courses: action}).then(r => r.data).then(r => {
          commit('setAllCourse', r)
        }).catch(error => {
          console.log(error)
        })
      }else{
        return Api.Course.index(filter).then(r => r.data).then(r => {
          commit('setAllCourse', r)
        }).catch(error => {
          console.log(error)
        })
      }
    },
    create({context, dispatch}, course){
      let notification = {};

      return Api.Course.createCourseWithFormData(course).then(r => r.data).then(r => {
        notification.type = 'success';
        notification.msg = 'Feito com sucesso';
        return r;
      }).catch(error => {
        notification.type = 'error';
        notification.msg = `Erro: ${error}`;
        throw error
      }).finally(() => {
        dispatch('Notification/add', notification, {root: true});
      });
    },
    update({context, dispatch}, course){
      let notification = {};

      return Api.Course.updateCourseWithFormData(course).then(r => r.data).then(r => {
        notification.type = 'success';
        notification.msg = 'Feito com sucesso';
        return r;
      }).catch(error => {
        notification.type = 'error';
        notification.msg = `Erro: ${error}`;
        throw error
      }).finally(() => {
        dispatch('Notification/add', notification, {root: true});
      });
    },
    delete(){},
  },
};

export default Course;