import Http from '../http';

export default {
  index(filter) {
    return Http.get(`/enrolleds`, {params: {...filter}})
  },
  
  update(enrolled){
    return Http.put(`/enrolleds/${enrolled.id}`, {
      enrolled: {
        ...enrolled,
      },
    });
  },
}