import Api from '@/api/index';
import dayjs from 'dayjs'

const DoneForm = {
  namespaced: true,

  state: {
    done_items_attributes: [],
    done_personal_info_attributes: {
      name: '',
      country: '',
      state: '',
      city: '',
      email: '',
      phone: '',
      curriculum_link: '',
      curriculum_file: '',
      birthdate: '',
    },
  },

  getters: {
  },

  mutations: {
    clearDoneForm(state){
      state.done_items_attributes = []
      state.done_personal_info_attributes = {
        name: '',
        country: '',
        state: '',
        city: '',
        email: '',
        phone: '',
        curriculum_link: '',
        curriculum_file: '',
        birthdate: '',
      }
    },

    addToDoneItemsAttributes(state, item){
      state.done_items_attributes = [
        ...state.done_items_attributes,
        {...item}
      ];
    },
    editInDoneItemsAttributes(state, item){
      let idx = state.done_items_attributes.findIndex(done_item => {
        return done_item.item_id == item.item_id;
      })
      state.done_items_attributes[idx] = {...item}
    },
  },

  actions: {
    clearDoneForm({commit}){
      commit('clearDoneForm')
    },
    editInDoneItemsAttributes({commit, dispatch}, item){
      commit('editInDoneItemsAttributes', item)
    },
    addToDoneItemsAttributes({commit, dispatch}, item){
      commit('addToDoneItemsAttributes', item)
    },
  },
};

export default DoneForm;