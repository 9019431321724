  /*
    - Use this to call in another VuexModule
    
    -- notification object:
      let notification = {
        type: 'error', // 'success
        msg: `Erro: I AM AN ERROR TEST` // success msg
      }

    -- dispatch an action in another vuex module:
      context.dispatch('Notification/add', notification, {root: true})
  */
const Notification = {
  namespaced: true,
  state: {
    value: 'my value',

    oneNotification: null, //to store SHOW response
    allNotifications: [], //to store SHOW response
  },
  getters: {
    getStateValue: state => {
      return state.value;
    }
  },

  mutations: {
    PUSH(state, notification){
      state.allNotifications.push({
        ...notification,
        created_at: new Date()
      })
    },
    DELETE(state, notificationToRemove){
      state.allNotifications = state.allNotifications.filter(o=>o.created_at != notificationToRemove.created_at)
    },
    
    clearAllNotifications(state){
      state.allNotifications = null
    },
    clearOneNotification(state){
      state.oneNotification = null
    },
  },

  actions: {
    clearAllNotifications({commit}){
      commit('clearAllNotifications')
    },
    clearOneNotification({commit}){
      commit('clearOneNotification')
    },

    add({commit}, notification){
      commit('PUSH', notification)
    },
    remove({commit}, notification){
      commit('DELETE', notification)
    },
  }

};

export default Notification;