<template>
  <v-card-text>
    <v-flex v-if="cardText" xs12 :class="['text-center', light? 'white--text' : 'primary--text']">{{loadingText}}...</v-flex>
    <!-- <v-progress-linear indeterminate color="white" class="mb-0" ></v-progress-linear> -->
    <v-flex xs12 class="mt-2 text-center">
      <v-progress-circular :size="cardSize" :width="cardWidth" :color="loadingColor" indeterminate></v-progress-circular>
    </v-flex>
  </v-card-text>
</template>

<script>
export default {
  name: "cardLoading",
  props: {
    loadingText: {
      type: String,
      default: () => "Carregando"
    },
    loadingColor: {
      type: String,
      default: () => "primary"
    },
    light:{
      type: Boolean,
      default: () => false
    },
    cardSize: {
      type: Number,
      default: () => 70
    },
    cardWidth: {
      type: Number,
      default: () => 7
    },
    cardText:{
      type: Boolean,
      default: () => true
    },
  }
};
</script>

<style scoped>
</style>