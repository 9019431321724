import Vue from "vue";
import Vuetify from "vuetify/lib";
import pt from 'vuetify/es5/locale/pt';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#1976D2",
        secondary: "#424242",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107"
      }
    },
    options: {
      customProperties: true
    }
  },
  lang: {
    locales: { pt },
    current: 'pt',
  },
  icons: {
    iconfont: "mdi",
    // default icons. Chamar em $vuetify.icons.[icon-value]
    values: {
      user: "mdi-account",
      list: "mdi-format-list-bulleted",
      logout: "mdi-logout",
      home: "mdi-home"
    }
  }
});
