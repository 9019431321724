import Http from '../http';

export default {
  show(id) {
    return Http.get(`/ghost_course_rating/${id}`, {
      headers: {
        "authorization": localStorage.ghost_tk
      }
    });
  },
  create(formData) {
    return Http.post('/ghost_course_rating', formData, {headers: {
      "authorization": localStorage.ghost_tk
    }})
  },
}