import Http from '../http';

export default {
  index(filter) {
    return Http.get(`/items`, {params: {...filter}})
  },
  indexByForm(form_id, filter) {
    return Http.get(`/forms/${form_id}/items`, {params: {...filter}})
  },
  create(item) {
    return Http.post('/items', {item: {
      ...item
    }})
  },
  createByForm(item, form_id) {
    return Http.post(`/forms/${form_id}/items`, {item: {
      ...item
    }})
  },
  delete(id){
    return Http.delete(`/items/${id}`)
  },
  update(item){
    return Http.put(`/items/${item.id}`, {
      item: {
        ...item,
      },
    });
  },
  show(id) {
    return Http.get(`/items/${id}`)
  },
}