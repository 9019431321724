export default {
  data() {
    return {
      // Validação comum - padrão, chamar apenas uma sem []
      nameRules: [
        v => !!v || "Nome é obrigatório",
        v => (v && v.length <= 40) || "O nome não pode ter mais 40 caracteres."
      ],
      emailRules: [
        v => !!v || "E-mail obrigatório",
        //v => /.+@.+\..+/.test(v) || "Precisa ser um e-mail valido"
        v => /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(v) || "Precisa ser um e-mail valido"
      ],
      required: [v => !!v || "Campo obrigatório"],
      required_phone: [
        v => !!v || "Campo obrigatório",
        v => (v && v.length >= 14 && v.length <= 15) || "Telefone inválido."
      ],
      requiredAtLeastCheckboxItem:[
        v=> (v && v.length > 0) || "Escolha uma opção"
      ],
      requiredRatingItem:[
        v=> (v && v > 0) || "Campo obrigatório",
      ],
      requiredUrl: [
        (v) => ( v && /^(https?|chrome):\/\/[^\s$.?#].[^\s]*$/.test(v) ) || 'Insira um link válido'
      ],
      requiredMin: [v => v >= 0 || "Campo obrigatório"],
    };
  },
  methods: {
    // Validação individual, chamar muliplas dentro de [method(), method(param)]
    ruleRequired(msg) {
      return v => !!v || msg;
    },
    ruleMail() {
      return v => /.+@.+\..+/.test(v) || "E-mail inválido";
    },
    ruleMaxLen(max) {
      return v => (v && v.length <= max) || "Máximo " + max + " caracteres";
    },
    ruleMinLen(min) {
      return v => (v && v.length >= min) || "Mínimo " + min + " caracteres";
    },
    ruleMatch(value2Match) {
      return v => (v && v == value2Match) || "Não combina";
    },
    ruleMaxGrade(max) {
      return v => (v && v <= max && v >= 0) || `O valor máximo de pontos é : ${max}`;
    },
    ruleMinValue() {
      return v => (v && v >= 0) || `Campo obrigatório ${v} `;
    },
    ruleFile(){
      return v => !!v || 'Selecione um arquivo'
    },
    ruleExtension(param, file_extension){
      return v => !!v && param.includes(v.type) || `Formato de arquivo incompatível, selecione um arquivo ${file_extension}`
    },
    ruleSize(){
      return v => !!v && v.size < 10000000 || 'Tamanho do arquivo acima do permitido ( maxímo 10MB )' // 10mb
    },
  }
};
