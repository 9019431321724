
<template>
  <div>
    <v-row class="mt-1">
      <v-col class="pt-0">
        <span class="text-h6">{{section_title}}</span>
        <v-file-input
          v-if="is_preview_only"
          label="Selecionar arquivo"
          :accept="input_permited_file_options" 
          v-model="file"
          @change="checkFileType(file) ? createLocalUrlFile() : null"
          :disabled="disabled"
          :rules="required_field && !file_name ? [test_if_file_exists(), file_rules_test()] : !file ? [] : [file_rules_test()]"
        ></v-file-input>
        <v-card-text 
          class="pt-2 px-2" 
          v-if="file_name && file_url || local_file_preview"
        >
          <v-icon class="mb-3">mdi-arrow-up-left</v-icon>
          <v-chip class="px-5" text color="primary" @click="dialog_file = true" >
            <v-icon left>mdi-file</v-icon>
            <span>{{!local_file_preview ? file_name : local_file_preview.name}}</span>
          </v-chip>
        </v-card-text>
      </v-col>
      <dialog-show-file 
        :title="!local_file_preview ? file_name : local_file_preview.name" 
        :file_url="!local_file_preview ? file_url : local_file_preview.url" 
        :file_kind="accepted_file_options == 'pdf' ? `application/${accepted_file_options}` : 'image/*'"
        :dialog_file="dialog_file" 
        @closeDialog="dialog_file = false"
      ></dialog-show-file>
    </v-row>
  </div>
</template>

<script>
import validations from '@/mixins/validations';

export default {
  name: 'InputLocalFilePreview',
  mixins: [
    validations
  ],
  props: {
    section_title: {
      type: String
    },
    file_name: {
      type: String,
      default: ''
    },
    file_url: {
      type: String,
      default: ''
    },
    accepted_file_options: {
      type: String, // img ou pdf
    },
    required_field: {
      type: Boolean,
      default: true 
    },
    disabled: {
      type: Boolean,
      default: true
    },
    is_preview_only:{
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      file: null,
      local_file_preview: null,
      dialog_file: false,
    }
  },
  methods: {
    test_if_file_exists(){
      return v => !!v || 'Selecione um arquivo'
    },
    file_rules_test(){
      return v => ( !!v && this.permited_extensions_rules.includes(v.type) ) || 'Formato de arquivo incompatível'
    },
    checkFileType(el){
      return !el ? true : (!!el && this.permited_extensions_rules.includes(el.type))
    },
    createLocalUrlFile(){
      if(this.file_url && this.file || this.file){
        var url  = window.URL.createObjectURL(this.file)
        this.local_file_preview = {
          url: url,
          name: this.file.name
        }
      }else{
        this.local_file_preview = null;
      }
      this.$emit('fileToUpload', this.file)
    },
  },
  computed: {
    input_permited_file_options() {
      let file_type = this.accepted_file_options
      return file_type == 'pdf' ? '.pdf' : 'image/jpg, image/png, image/jpeg'
    },
    permited_extensions_rules(){
      let file_type = this.accepted_file_options
      return file_type == 'pdf' ? ['application/pdf'] : ['image/jpg', 'image/png', 'image/jpeg']
    }
  },
}
</script>

<style scoped>

</style>