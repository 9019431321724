<template>
  <div>
    <v-card
      dark
      max-width="500px"
      style="border-radius: 18px"
      :class="['ma-auto pa-3', elevation ? 'elevation-4' : '' ]"
      :color="card_color" 
      :flat="!elevation"
    >
      <v-card-text>
        <v-flex xs12 :class="['text-center', card_color == 'primary' ? 'white--text' : 'primary--text']">
          {{loadingText}}...
        </v-flex>
        <!-- <v-progress-linear indeterminate color="white" class="mb-0" ></v-progress-linear> -->
        <v-flex xs12 class="mt-2 text-center">
          <v-progress-circular :size="loading_size" :width="progress_width" :color="card_color == 'primary' ? 'white' : 'primary'" indeterminate></v-progress-circular>
        </v-flex>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
  export default {
    name: 'cardLoading',
    props: {
      loadingText: {
        type: String,
        default: () => 'Carregando'
      },
      card_color: {
        type: String,
        default: 'primary'
      },
      loading_size: {
        type: Number,
        default: 70
      },
      elevation: {
        type: Boolean,
        default: true
      },
      progress_width:{
        type: Number,
        default: 7
      }
    },
  }
</script>

<style scoped>

</style>