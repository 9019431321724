import Http from '../http';

export default {
  index(filter) {
    return Http.get(`/courses`, {params: {...filter}})
  },
  create(course) {
    return Http.post('/courses', {course: {...course}})
  },
  update(course){
    return Http.put(`/courses/${course.id}`, {
      course: {
        ...course,
      },
    });
  },
  show(id) {
    return Http.get(`/courses/${id}`)
  },
  createCourseWithFormData(course) {
    return Http.post('/courses', course)
  },
  updateCourseWithFormData(course){
    return Http.put(`/courses/${course.get('course[id]')}`, course);
  },
}