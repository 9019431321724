/* eslint-disable */
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import { store, The_Ability } from "./store";
// import store from "./store";
import vuetify from "./plugins/vuetify";
import i18n from './i18n'

import "./registerServiceWorker";

import "./plugins/index";

import { abilitiesPlugin } from "@casl/vue";
Vue.use(abilitiesPlugin, The_Ability)

import CardLoadingTransparent from "./components/shared/CardLoadingTransparent";
import CardLoading from "./components/shared/CardLoading";
import DialogShowFile from "./components/shared/DialogShowFile";
import DialogLoading from "./components/shared/DialogLoading";
import SelectDateFilter from "./components/shared/filter_date/SelectDateFilter";
import PageHeader from "./components/shared/PageHeader";
import DatePicker from "./components/shared/DatePicker";
import TimePicker from "./components/shared/TimePicker";
import CountryFlag from 'vue-country-flag';
import InputLocalFileWithPreview from "./components/shared/InputLocalFileWithPreview";
import YearPicker from "./components/shared/YearPicker";

Vue.component('country-flag', CountryFlag)
Vue.component("page-header", PageHeader);
Vue.component("dialog-loading", DialogLoading);
Vue.component("dialog-show-file", DialogShowFile);
Vue.component("card-loading", CardLoading);
Vue.component("card-loading-transparent", CardLoadingTransparent);
Vue.component("select-date-filter", SelectDateFilter);
Vue.component("date-picker", DatePicker);
Vue.component("time-picker", TimePicker);
Vue.component('input-local-file-with-preview', InputLocalFileWithPreview)
Vue.component('year-picker', YearPicker)

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  i18n,
  The_Ability,

  render: h => h(App)
}).$mount("#app");
/* eslint-enable */
