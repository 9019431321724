import Vue from "vue";
import store from "../store";

import auth from "@websanova/vue-auth";
import authDevise from "@websanova/vue-auth/drivers/auth/devise.js";
import authHttp from "@websanova/vue-auth/drivers/http/axios.1.x.js";
import authRouter from "@websanova/vue-auth/drivers/router/vue-router.2.x.js";

Vue.use(auth, {
  // DRIVERS
  auth: authDevise,
  http: authHttp,
  router: authRouter,

  // TOKENS
  tokenDefaultKey: "auth_token_default",
  rememberkey: "auth_remember",
  tokenImpersonateKey: "auth_token_impersonate",
  stores: ["storage", "cookie"],

  rolesKey: "roles",
  cookie: {
    Path: "/",
    Domain: null,
    Secure: true,
    Expires: 12096e5,
    SameSite: "None"
  },

  // METHODS PARAMETERS
  loginData: {
    url: "api/auth/sign_in",
    method: "POST",
    redirect: "/",
    fetchUser: true
  },
  logoutData: {
    url: "api/auth/sign_out",
    method: "DELETE",
    redirect: "/",
    fetchUser: false,
    makeRequest: true
  },
  registerData: {
    url: "api/auth/",
    method: "POST",
    redirect: "/need_confirm",
    autoLogin: false
  },

  // default = /404 (ex: usuário logado tentar acessar /login ele retorna 404. Mas melhor deixar home pela Ux)
  notFoundRedirect: { path: "/" },

  refreshData: { url: "api/auth/validate_token", enabled: true, method: "GET" },
  fetchData: {
    url: "api/auth/validate_token",
    method: "GET",
    enabled: true,
    interval: 30
  },

  // Usuário é definido no vuex sempre que for detectado pelo vue-auth
  parseUserData: function(response) {
    console.log("Usuário encontrado...");
    // store.commit("User/login", response.data);
    return response.data;
  }
});
