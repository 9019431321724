import User from './modules/UserApi';
import Course from './modules/CoursesApi';
import Role from './modules/RoleApi';
import Form from './modules/FormsApi';
import Item from './modules/ItemsApi';
import DoneForm from './modules/DoneFormApi';
import DoneItems from './modules/DoneItemsApi';
import MyCourse from './modules/MyCourseApi';
import EnrollCourseValidate from './modules/EnrollCourseValidateApi';
import Enrolled from './modules/EnrolledApi';
import DashCounter from './modules/DashCounterApi';
import RatingDashboard from './modules/RatingDashboardApi';
import CertificateSetting from './modules/CertificateSettingApi';
import GhostRating from './modules/GhostRatingApi';
import DonePersonalInfos from './modules/DonePersonalInfosApi';
import GeneralImages from './modules/GeneralImagesApi';
import DataExport from './modules/DataExportApi';
import TeacherCertificates from './modules/TeacherCertificatesApi';

export default {
  User,
  Course,
  Role,
  Form,
  Item,
  DoneForm,
  DoneItems,
  MyCourse,
  EnrollCourseValidate,
  Enrolled,
  DashCounter,
  RatingDashboard,
  CertificateSetting,
  GhostRating,
  DonePersonalInfos,
  GeneralImages,
  DataExport,
  TeacherCertificates
}