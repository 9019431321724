<template>
  <v-app style="background-color: #F1F1F1">
    <Header></Header>
    <side-bar></side-bar>
    <app-notifications></app-notifications>
    <v-main>
      <router-view></router-view>
    </v-main>
    <!-- <bottom-bar></bottom-bar> -->
  </v-app>
</template>

<script>
import AppNotifications from './components/notification/Notifications';
import { mapActions } from "vuex";

export default {
  name: "App",
  components: {
    Header: () => import("@/components/shared/_header_01.vue"),
    SideBar: () => import("@/components/shared/_drawer_01.vue"),
    BottomBar: () => import("@/components/shared/_bottom_01.vue"),
    AppNotifications,
  },
  data() {
    return {
      logo_left: null
    }
  },
  computed: {
    authReady() {
      return this.$auth.check();
    },
  },
  methods: {
    ...mapActions({
      setLogos: "App/setLogos"
    }),
  },
  watch: {
    authReady(val) {
      if (val) {
        // console.log("comit");
        // console.log(this.$auth.user())
        this.$store.commit("User/login", this.$auth.user());
      }
    },
  },
  created () {
    this.setLogos();
  },
};
</script>
<style>
/* #app { */
/* Cor identidade visual do app - background */
/* background-color: var(--v-primary-base) !important; */
/* } */

html {
  /* Remove o scroll bar null */
  overflow-y: auto !important;
}
</style>
