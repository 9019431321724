
<template>
  <div>
    <v-row align="center" justify="end" class="px-3">
      <v-menu ref="menu_date_filter" v-model='menu' offset-y :close-on-content-click="false"
        :close-on-click="false"
        :return-value.sync="final_date"
      >
        <template v-slot:activator="{ on }">
          <v-chip v-on="on" style="border-radius: 8px;" >
            <v-avatar left>
              <v-icon>mdi-calendar</v-icon>
            </v-avatar>
            
          </v-chip>
        </template>

        <template v-if="!is_multiple">
          <v-date-picker v-model="one_date" :type="date_kind" scrollable locale="pt-BR" :range="false" color="primary">
            <v-btn text color="error" @click="clearDateFilter()">
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="submitDate(one_date)">
            </v-btn>
          </v-date-picker>
        </template>

        <template v-if="is_multiple">
          <v-date-picker v-model="date_range" :type="date_kind" scrollable locale="pt-BR" :range="true" color="primary">
            <v-btn text color="error" @click="clearDateFilter()">
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn text color="primary" :disabled="date_range.length!=2" @click="submitDate(date_range)">
            </v-btn>
          </v-date-picker>
        </template>

      </v-menu>

      <v-dialog v-if="false" v-model="dialog_set_date" max-width="500px" transition="dialog-transition" >
        <v-card style="border-radius:12px">
          <v-card-title primary-title>
            <div class="headline mb-0">Pesquisa por data</div>
          </v-card-title>

          <v-card-text>
            <v-dialog ref="dialog_date_filter" :return-value.sync="date_range" width="290px" >
              <template v-slot:activator="{ on }">
                <v-text-field v-on="on" :value="date_in_input" clearable @click:clear="clearDateFilter()" clear-icon="mdi-close mdi-18px"
                  :label="date_label" prepend-icon="event" readonly
                ></v-text-field>
              </template>

              <template v-if="!is_multiple">
                <v-date-picker v-model="one_date" :type="date_kind" scrollable locale="pt-BR" :range="false" color="primary">
                  <v-spacer></v-spacer>
                  <!-- <v-btn text color="primary" @click="modal = false">Cancel</v-btn> -->
                  <v-btn text color="primary" @click="submitDate(one_date)">OK</v-btn>
                </v-date-picker>
              </template>

              <template v-if="is_multiple">
                <v-date-picker v-model="date_range" :type="date_kind" scrollable locale="pt-BR" :range="true" color="primary">
                  <v-spacer></v-spacer>
                  <!-- <v-btn text color="primary" @click="modal = false">Cancel</v-btn> -->
                  <v-btn text color="primary" @click="submitDate(date_range)">OK</v-btn>
                </v-date-picker>
              </template>

            </v-dialog>

          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn text color="error" @click="dialog_set_date = false">Cancelar</v-btn>
            <v-btn :disabled="date_range.length==0" color="primary" @click="setDatesAndFetch()"><v-icon left>mdi-magnify</v-icon>OK</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- <v-btn :disabled="date_range.length==0" small text fab dark color="white" @click="fetchFilteredItems()"><v-icon>mdi-magnify</v-icon></v-btn> -->
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'

export default {
  name:"SelectDateFilter",
  props: {
    date_kind:{
      type: String,
      default: 'date',
    },
    is_multiple: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      one_date: this.$dayjs().format('YYYY-MM-DD'),
      last_date: null,

      date_range: [
        this.$dayjs().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'),
        this.$dayjs().endOf('month').format('YYYY-MM-DD'),
      ],

      final_date: this.$dayjs().format('YYYY-MM-DD'),

      dialog_set_date: false,

      menu: false,
    }
  },
  methods: {
    setFinalDate(d){
      if (d){
        this.final_date = d
      } else {
        if (this.is_multiple){
          if (this.date_kind == 'month'){
            this.date_range = [
              this.$dayjs().subtract(1, 'month').startOf('month').format('YYYY-MM'),
              this.$dayjs().startOf('month').format('YYYY-MM'),
            ]
          }
          this.final_date = this.date_range
        } else {
          if (this.date_kind == 'month'){
            this.one_date = this.$dayjs().format('YYYY-MM')
          }
          this.final_date = this.one_date
        }
      }
      this.$emit('doneDate', this.date_to_emit)

    },
    clearDateFilter() {
      this.menu = false
      if (this.is_multiple){
        this.date_range = this.final_date
      } else {
        this.one_date = this.final_date
      }
      this.last_date = null
    },
    submitDate(date){
      this.$refs.menu_date_filter.save(date)
      this.$emit('doneDate', this.date_to_emit)
    },
    ...mapActions({
      // function: 'Module/action'
    })
  },
  mounted () {
    this.setFinalDate()
  },
  computed: {
    date_in_string() {
      let d = ''
      if (this.is_multiple){
        d = this.date_range
        if (Array.isArray(d)){
          d = d.sort((a,b)=> this.$dayjs(a).diff(this.$dayjs(b), 's') > 0? 1 : -1).map((dd, idx)=>{
            if (this.date_kind == 'month'){
              return this.$dayjs(dd).format('MMM/YY')
            }
            return this.$dayjs(dd).format('DD/MM')
          })
          let to_text = 'até' // this.$t('date_filter_to')
          d = d.join(` ${to_text} `)
        }
      } else {
        d = this.final_date || this.one_date
        if (this.date_kind == 'month'){
          d = this.$dayjs(d+'-01').format('MMM/YY')
        }else {
          d = this.$dayjs(d).format('LL')
        }
      }
      return d
    },
    date_in_input(){
      let d = ''
      return d
    },
    date_to_emit(){
      let d = null
      if (this.is_multiple){
        let start_date = this.final_date[0]
        let end_date = this.final_date[1]
        if (this.date_kind == 'month'){
          start_date = this.$dayjs(start_date+'-01').startOf('month').format()
          end_date = this.$dayjs(end_date+'-01').endOf('month').format()
        } else {
          start_date = this.$dayjs(start_date).startOf('day').format()
          end_date = this.$dayjs(end_date).endOf('day').format()
        }
        d = {
          start_date,
          end_date,
        }
      } else {
        d = this.final_date
        let start_date = null
        let end_date = null
        if (this.date_kind == 'month'){
          start_date = this.$dayjs(d+'-01').startOf('month').format()
          end_date = this.$dayjs(d+'-01').endOf('month').format()
        } else {
          start_date = this.$dayjs(d).startOf('day').format()
          end_date = this.$dayjs(d).endOf('day').format()
        }
        d = {
          start_date,
          end_date,
        }
      }
      return d
    },
    date_label(){
      let d = ''
      return d
    },
    ...mapState({
      // data: state=> state.Module.state_var
    })
  },
  watch: {
    final_date(nv,ov) {
      this.$emit('doneDate', this.date_to_emit)
      if (nv){
      } else if (nv == false){
        // this.clearDateFilter()
      }
    }
  },
}
</script>

<style scoped>

</style>
