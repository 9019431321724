import Http from '../http'

export default {

  index() {
    return Http.get(`/certificate_settings`)
  },

  createWithFormData(certificate_setting_formData){
    return Http.post(`/certificate_settings`, certificate_setting_formData)
  },

  updateWithFormData(certificate_setting_formData){
    return Http.put(`/certificate_settings/${certificate_setting_formData.get('certificate_setting[id]')}`, certificate_setting_formData);
  },

  show(id) {
    return Http.get(`/certificate_settings/${id}`);
  },

  delete(id){
    return Http.delete(`/certificate_settings/${id}`)
  }

}
