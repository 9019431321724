
<template>
  <div class="notices">
    <!-- <transition-group name="slide-fade" mode="out-in" appear> -->
      <one-notification v-for="n in allNotifications" :key="n.created_at.toString()" :notification="n"></one-notification>
    <!-- </transition-group> -->
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'

export default {
  components: {
    OneNotification: () => import('./OneNotification'),
  },
  data() {
    return {
      key: null
    }
  },
  methods: {
    names() {
      
    },
    ...mapActions({
      // function: 'Module/action'
    })
  },
  computed: {
    name() {
      return null 
    },
    ...mapState({
      allNotifications: state => state.Notification.allNotifications,
      // data: state=> state.Module.state_var
    })
  },
  watch: {
    data(nv,ov) {
      if (nv){
      } else if (nv == false){
      }
    }
  },
}
</script>

<style scoped>

</style>