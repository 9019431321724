import Http from '../http';

export default {
  index(filter) {
    return Http.get(`/teacher_certificates`, {params: {...filter}})
  },
  show(id) {
    return Http.get(`/teacher_certificates/${id}`)
  },
  create(teachers, course_id){
    return Http.post(`/teacher_certificates`, { teacher_certificate: {teachers: [...teachers], course_id: course_id }} )
  },
}