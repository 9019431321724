
<template>
  <div class="my-5">
    <v-row class="px-5" align="center">
      <span class="mr-5 headline">{{title}}</span>
      <v-divider style="box-shadow: 0px 0px 3px grey;"></v-divider>
      <slot name='action'></slot>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'

export default {
  name: 'PageHeader',
  props: {
    title: {
      type: String,
    },
  },
  data() {
    return {
      key: null
    }
  },
  methods: {
    ...mapActions({
      // function: 'Module/action'
    })
  },
  computed: {
    name() {
      return null 
    },
    ...mapState({
      // data: state=> state.Module.state_var
    })
  },
  watch: {
  },
}
</script>

<style scoped>

</style>