const authRoutes = [
  {
    path: "/login",
    name: "Login",
    component: () => import("./pages/Login.vue"),
    meta: {
      auth: false,
      
    }
  },
  {
    path: "/logout",
    name: "Logout",
    component: () => import("./pages/Logout.vue"),
    meta: {
      auth: true,
      
    }
  },
  {
    path: "/need_confirm",
    name: "NeedConfirm",
    component: () => import("./pages/ConfirmEmail.vue"),
    meta: {
      auth: false,
      
    }
  },
  {
    path: "/register_success",
    name: "RegisterSuccess",
    component: () => import("./pages/RegisterSuccess.vue"),
    meta: {
      auth: false,
      
    }
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("./pages/Register.vue"),
    meta: {
      auth: false,
      
    }
  },
  {
    path: "/recover",
    name: "Recover",
    component: () => import("./pages/Recover.vue"),
    meta: {
      auth: false,
      
    }
  },
  {
    path: "/password",
    name: "Password",
    component: () => import("./pages/Password.vue"),
    meta: {
      auth: false,
      
    }
  }
];

export default authRoutes;
