import Http from '../http';

export default {
  index(filter) {
    return Http.get(`/general_images`, {params: {...filter}})
  },
  create(general_image) {
    return Http.post('/general_images', general_image)
  },
  show(id) {
    return Http.get(`/general_images/${id}`)
  },

  update(general_image){
    return Http.put(`/general_images/${general_image.get('general_image[id]')}`, general_image);
  },

  delete(id){
    return Http.delete(`/general_images/${id}`)
  }
}