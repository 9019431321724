<template>
  <div>
    <v-dialog
      v-model="dialog_file"
      scrollable
      max-width="800"
      transition="dialog-transition"
      persistent
      :fullscreen="fullScreenDialog"
    >
      <v-card :style="fullScreenDialog ? 'border-radius:0px' : 'border-radius:12px'" v-if="dialog_file">
        <v-card-title 
          primary-title 
          dark
          color="primary"
          :class="fullScreenDialog ? 'primary white--text py-3 px-5' : ''"
        > 
          <v-col>
            <v-row>
              <div class="title mb-0">Visualização</div>
              <v-spacer></v-spacer>
              <v-btn 
                text 
                rounded
                :color="fullScreenDialog ? 'white' : 'primary'"
                class="ml-2" 
                @click="fullScreenDialog =! fullScreenDialog" 
              > {{ fullScreenDialog ? 'Reduzir' : 'Ampliar'}} 
                <v-icon small class="ml-2">{{ fullScreenDialog ? 'mdi-arrow-bottom-left' : 'mdi-arrow-top-right'}}</v-icon>
              </v-btn>
              <v-btn 
                text 
                rounded
                icon 
                :color="fullScreenDialog ? 'white' : 'primary'"
                class="ml-2" 
                @click="$emit('closeDialog')" 
              ><v-icon>mdi-close</v-icon></v-btn>
            </v-row>
            <v-row>
              <div class="subtitle-1 mb-0"><span class="font-weight-medium">Nome do arquivo: </span>{{title}}</div>
            </v-row>
          </v-col>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <pdf 
            v-if="file_kind == 'application/pdf'" :src="file_url" 
            @num-pages="pageCount = $event"
            @page-loaded="currentPage = $event" 
            :page="currentPage"
          ></pdf>
          <img width="100%" v-if="file_kind != 'application/pdf'" :src="file_url"/>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-end">
          <div v-if="file_kind == 'application/pdf'">
            <span class="mr-4 grey--text">Página: {{currentPage}} / {{pageCount}}</span>
            <v-btn 
              depressed
              elevation="2"
              fab
              x-small
              class="primary"
              @click="previousPage"
              :disabled="currentPage <= pageCount && currentPage == 1"
            ><v-icon color="white">mdi-chevron-left</v-icon></v-btn>
            <v-btn 
              depressed
              elevation="2"
              fab
              x-small
              class="primary ml-3"
              @click="nextPage"
              :disabled="currentPage <= pageCount && currentPage == pageCount"
            ><v-icon color="white">mdi-chevron-right</v-icon></v-btn>
          </div>
          <v-spacer></v-spacer>
          <v-btn v-if="file_url" text download color="primary" :href="file_url" target="_blank" :disabled="disabled_btn_in_preview">Download</v-btn>
          <v-btn text color="error" @click="$emit('closeDialog')">Sair</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import pdf from 'vue-pdf'
export default {
  name: 'DialogShowFile',
  components: {
    pdf
  },
  props: {
    file_url: {
      type: String,
      default: null
    },
    file_kind: {
      type: String,
      default: null
    },
    dialog_file: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: null
    },
  },
  data() {
    return {
      currentPage: 1,
			pageCount: 1,
      fullScreenDialog: false
    }
  },
  methods: {
    nextPage(){
      if(this.currentPage <= this.pageCount){
        this.currentPage =  this.currentPage + 1
      }
    },
    previousPage() {
      if(this.currentPage <= this.pageCount && this.currentPage > 1){
        this.currentPage =  this.currentPage - 1
      }
    },
  },
  watch: {
    file_url(){
      this.currentPage = 1
    },
    dialog_file(){
      this.fullScreenDialog = false
    }
  },
  computed: {
    disabled_btn_in_preview() {
      let blob = this.file_url
      return blob.toString().substring(0,4) == 'blob'
    }
  },

}
</script>

<style lang="scss" scoped>

</style>