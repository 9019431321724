let Admin = ['super_admin', 'admin', 'director']
let Coordinator = 'coordinator'
let Student = 'student'

const CertificateConfig = [
  {
    path: '/certificate_config',
    name: 'CertificateConfig',
    meta: { 
      auth: true, 
      tab_name: 'Configuração de certificado',
      roles: [Admin],
    },
    component: () => import('@/pages/certificate_config/CertificateConfigPage.vue'),

    children: [
      {
        path: '/certificate_config/new',
        name: 'NewCertificateConfig',
        meta: { 
          auth: true, 
          tab_name: 'Adicionar assinatura',
          roles: [Admin],
        },
        component: () => import('@/components/certificate_config/create/CertificateCreate.vue'),
      },
      {
        path: '/certificate_config/edit/:id',
        name: 'EditCertificateConfig',
        meta: { 
          auth: true, 
          tab_name: 'Editar Assinatura',
          roles: [Admin],
        },
        component: () => import('@/components/certificate_config/create/CertificateCreate.vue'),
      },



      {
        path: '/certificate_config/new_logo',
        name: 'NewLogo',
        meta: { 
          auth: true, 
          tab_name: 'Adicionar logo',
          roles: [Admin],
        },
        component: () => import('@/components/certificate_config/create/GeneralImagesEdit.vue'),
      },
      {
        path: '/certificate_config/edit_logo/:id',
        name: 'EditLogo',
        meta: { 
          auth: true, 
          tab_name: 'Editar logo',
          roles: [Admin],
        },
        component: () => import('@/components/certificate_config/create/GeneralImagesEdit.vue'),
      },
      

    ]
  },
  {
    path: '/course/:id/certificate_list',
    name: 'ConcludedCourseCertificates',
    meta: { 
      auth: true, 
      tab_name: 'Lista de certificados de curso concluído',
      roles: [Admin, 'tech_secretary', 'coordinator'],
      permissions: { act: 'read', on: 'Enrolled' } // duvida ? opçao de suspender esta na pagina
    },
    component: () => import(`@/pages/course_certificate_list/CourseCertificateList.vue`)
  },
  {
    path: '/course/:id/teacher_certificate_list',
    name: 'TeacherCertificateList',
    meta: {
      auth: true,
      tab_name: 'Lista de certificados de cursos ministrados',
      roles: [Admin, 'tech_secretary', 'coordinator'],
      permissions: { act: 'read', on: 'Course' }
    },
    component: () => import(`@/pages/course_certificate_list/TeacherCertificateList.vue`),
    children: [
      {
        path: 'create',
        name: 'CreateTeacherCertificate',
        meta: {
          auth: true,
          tab_name: 'Gerar certificado professores.',
          roles: [Admin, 'tech_secretary', 'coordinator'],
          permissions: { act: 'read', on: 'Course' }
        },
        component: () => import(`@/components/course_certificate_list/CreateTeacherCertificateDialog.vue`),
      }
      
    ]
  }
]


export default CertificateConfig