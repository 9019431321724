import Api from '@/api/index'

const App = {
  namespaced: true,

  state: {
    drawer: false,
    logo_left: null,
    logo_right: null,
    country_flags: []
  },
  getters: {
    getDrawer: state => state.drawer
  },
  mutations: {
    setDrawer(state, payload) {
      state.drawer = payload;
    },
    setLogos(state, payload) {
      if(payload.key != 'country_flag'){
        state[`${payload.key}`] = payload.url
      }else{
        state.country_flags = [...state.country_flags, payload.url]
      }
    }
  },
  actions: {
    setDrawer(context, payload) {
      context.commit("setDrawer", payload);
    },
    setLogos(context, payload) {
      Api.GeneralImages.index().then(r => {
        r.data.map(el => {
          context.commit("setLogos", {key: el.kind, url: el.attached_file_url});
        })
      }).catch((err) => {
        context.dispatch('Notification/add', {type: 'error', msg: `Ocorreu um erro. Algumas informações do sistema não foram carregadas.`}, {root: true})
      })
    }
  }
};

export default App;
