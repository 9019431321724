import Http from '../http';

export default {
  index(filter) {
    return Http.get(`/done_forms`, {params: {...filter}})
  },
  create(done_form) {
    return Http.post('/done_forms', {done_form: {
      ...done_form
    }})
  },
  createWithFormData(formData) {
    return Http.post('/done_forms', formData)
  },
  update(done_form){
    return Http.put(`/done_forms/${done_form.id}`, {
      done_form: {
        ...done_form,
      },
    });
  },
  show(id) {
    return Http.get(`/done_forms/${id}`)
  },
}