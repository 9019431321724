import Vue from "vue";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

Vue.use(VueSweetalert2);
const swal = Vue.prototype.$swal;

const sweetAlerts = {
  toast1(kind, msg) {
    const Toast = swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 2000,
      timerProgressBar: true,
      onOpen: toast => {
        toast.addEventListener("mouseenter", swal.stopTimer);
        toast.addEventListener("mouseleave", swal.resumeTimer);
      }
    });
    Toast.fire({
      icon: kind,
      title: msg
    });
  }
};

Vue.prototype.$sweetAlerts = sweetAlerts;
