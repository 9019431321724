import Http from '../http';

export default {
  index(filter) {
    return Http.get(`/forms`, {params: {...filter}})
  },
  create(form) {
    return Http.post('/forms', {form: {
      ...form
    }})
  },
  update(form){
    return Http.put(`/forms/${form.id}`, {
      form: {
        ...form,
      },
    });
  },
  show(id) {
    return Http.get(`/forms/${id}`)
  },
}