const main_path = '@/pages/user'

let Admin = ['super_admin', 'admin', 'director']
let Coordinator = 'coordinator'
let Student = 'student'

const UserRoutes = [
  // {
  //   path: '/users',
  //   name: 'Users',
  //   meta: { 
  //     auth: true, 
  //     tab_name: 'Usuários',
  //     roles: [Admin, Coordinator, Student],
  //   },
  //   component: () => import(`@/pages/user/Users`)
  // },
  {
    path: '/account',
    name: 'ShowUser',
    meta: { 
      auth: true, 
      tab_name: 'Usuários',
      roles: [Admin, Coordinator, Student],
      permissions: { act: 'update', on: 'User' } // duvida ?
    },
    component: () => import(`@/pages/user/ShowUser`)
  },
  // {
  //   path: '/new_user',
  //   name: 'CreateUser',
  //   meta: { 
  //     auth: true, 
  //     tab_name: 'Usuários',
  //     roles: [Admin, Coordinator, Student],
  //   },
  //   component: () => import(`@/pages/user/CreateUser`) 
  // },
]

export default UserRoutes
